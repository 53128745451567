import { useState } from 'react';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { AddSubGroupMC } from '../../../AddSubGroupMC';
import { SubGroupCard } from '../../../SubGroupCard';
import s from '../../styles/Content.module.scss';

const HARDCODE = [
	{
		id: '1',
		name: 'Group 1',
		count: 4,
	},
	{
		id: '2',
		name: 'Group 2',
		count: 2,
	},
];

export const TabGroups = () => {
	// * Modals
	const [isOpenForm, setIsOpenForm] = useState(false);
	const toggleFormModal = () => setIsOpenForm(prevState => !prevState);

	// * Actions
	const addGroup = (data: { name: string }) => {
		console.log('add group: ', data.name);
	};

	const editGroup = (id: string, name: string) => {
		console.log('edit group ', name, ' by id ', id);
	};

	const deleteGroup = (id: string) => {
		console.log('delete group by id ', id);
	};

	// * Render
	return (
		<>
			{isOpenForm && (
				<ModalNewProto
					isOpen={isOpenForm}
					onClose={toggleFormModal}
					width="s"
				>
					<AddSubGroupMC
						type="group"
						closeModal={toggleFormModal}
						onSubmitAction={addGroup}
					/>
				</ModalNewProto>
			)}

			<div className={s.tab_container}>
				<AddButton
					title="Добавить группу"
					onClick={toggleFormModal}
				/>

				{HARDCODE.map(group => (
					<SubGroupCard
						key={group.id}
						{...group}
						type="group"
						editAction={editGroup}
						deleteAction={deleteGroup}
					/>
				))}
			</div>
		</>
	);
};
