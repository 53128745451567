import { FC } from 'react';
import { PageDecorator } from 'src/app/decorators/page_decorators';
import { Content } from '../Content/ui/Content';
import { Header } from '../Header';

const isCustom = true;

export const GroupPage: FC = () => {
	// * Render
	return (
		<PageDecorator>
			<Header isCustom={isCustom} />
			<Content isCustom={isCustom} />
		</PageDecorator>
	);
};
