import { UseFormSetValue } from 'react-hook-form';
import { WorkTimeResponse } from 'src/app/redux/queries/time-service/types/resTypes';
import { ProjectInfo } from '../types';
import { FormValues } from '../types';

export const setDescriptionAndHours = (setValue: UseFormSetValue<FormValues>, selectedProject: ProjectInfo, workTimesInfo: WorkTimeResponse[]) => {
	const projectsWorkTimeInfo = workTimesInfo.find(workTime => workTime.workTime.project.id === selectedProject.id)?.workTime;

	const managerHours = projectsWorkTimeInfo?.managerHours;
	const userHours = projectsWorkTimeInfo?.userHours;
	const managerDescription = projectsWorkTimeInfo?.managerDescription;
	const userDescription = projectsWorkTimeInfo?.description;

	let hours = null;
	let description = '';

	if (managerHours !== null && managerHours !== undefined) {
		hours = managerHours;
	} else if (userHours !== null && userHours !== undefined) {
		hours = userHours;
	}

	if (managerDescription !== null && managerDescription !== undefined) {
		description = managerDescription;
	} else if (userDescription !== null && userDescription !== undefined) {
		description = userDescription;
	}

	setValue('description', description);
	setValue('hours', hours);
};
