import { useState } from 'react';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';

export const TabUsers = () => {
	const [isOpen, setIsOpen] = useState(false);
	const toggleModal = () => setIsOpen(prevState => !prevState);

	// * Render
	return (
		<div>
			<AddButton
				title="Добавить сотрудников "
				onClick={toggleModal}
			/>
		</div>
	);
};
