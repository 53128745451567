import { Heading } from 'src/shared/ui/Heading';
import s from './SendEmailMC.module.scss';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { MCType } from '../../types';

interface FormValues {
	theme: string | null;
	content: string | null;
}

interface Props {
	toggleModal: () => void;
	setActiveMC: React.Dispatch<React.SetStateAction<MCType>>;
}

export const SendEmailMC: React.FC<Props> = props => {
	const {
		toggleModal, //
		setActiveMC,
	} = props;

	const onSaveClick = () => {
		// TODO
	};

	const onCancelClick = () => {
		toggleModal();
	};

	const onAddButtonClick = () => {
		setActiveMC('addressee');
	};

	// * Form
	const defaultValues = {
		theme: null,
		content: null,
	};

	const schema = yup.object().shape({
		theme: yup.string().required('Укажите тему письма'),
		content: yup.string().required('Письмо не должно быть пустым'),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit } = formMethods;

	const onSubmit = (values: FormValues) => {
		// TODO
		console.log(values);
	};

	// * Render
	return (
		<div>
			<Heading
				level={2}
				marginBottom="l"
			>
				Отправить e-mail
			</Heading>

			<FormProvider {...formMethods}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className={s.form}
				>
					<h4>Письмо</h4>
					<div className={s.fields}>
						<StringField
							name="theme"
							label="Тема письма"
							placeholder="Введите тему письма"
						/>

						<TextAreaField
							name="content"
							label="Текст письма"
							placeholder="Введите текст письма"
						/>
					</div>

					<h4>Адресаты</h4>
					<AddButton
						className={s.addButton}
						title="Добавить адресата"
						onClick={onAddButtonClick}
					/>

					<ButtonPair
						primaryText="Сохранить"
						primaryOnClick={onSaveClick}
						primaryIsLoading={false} // TODO
						primaryDisabled={false} // TODO
						secondaryText="Отмена"
						secondaryOnClick={onCancelClick}
						secondaryIsLoading={false} // TODO
					/>
				</form>
			</FormProvider>
		</div>
	);
};
