import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { office_serviceAPI, useGetBookingFindQuery } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { RoomResponseRead } from 'src/app/redux/queries/office-service/types/resTypes';
import { RoomInfoRead, WorkspaceInfo } from 'src/app/redux/queries/office-service/types/types';
import { useAppSelector } from 'src/app/redux/utils';
import { injectTimeToDate } from 'src/shared/lib/date';
import { BookingInfoCardData } from '../../../BookingInfoCard/types';

// TODO: Заменить этим код в MyBookingCard
export const useGetBookingInfo = () => {
	// * Selectors
	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	// * States
	const [allRooms, setAllRooms] = useState<RoomInfoRead[]>([]);
	const [allWorkspaces, setAllWorkspaces] = useState<WorkspaceInfo[]>([]);

	// * API
	const { data: officesData, isLoading: isOfficesLoading } = office_serviceAPI.useGetOfficeFindQuery({
		skipcount: 0,
		takecount: 1000,
	});
	const officeList = officesData?.body || [];

	const { currentData: bookingData, isLoading: isBookingLoading } = useGetBookingFindQuery({
		skipcount: 0,
		takecount: 1000,
		upcomingOnly: false,
		userId,
		includeBans: false,
	});

	const [getRoom, { isLoading: isGetRoomLoading }] = office_serviceAPI.useLazyGetRoomGetQuery();
	const [findRooms, { isLoading: isFindRoomLoading }] = office_serviceAPI.useLazyGetRoomFindQuery();

	const isLoading = isOfficesLoading || isBookingLoading || isGetRoomLoading || isFindRoomLoading;

	// * Initial
	useEffect(() => {
		findRooms({
			skipcount: 0,
			takecount: 1000,
		})
			.unwrap()
			.then(res => {
				if (!res.body) return;
				const promises = res.body.map(room => {
					return getRoom({
						roomId: room.id,
					}).unwrap();
				});

				Promise.allSettled(promises)
					.then(res => {
						const values: RoomResponseRead[] = res?.filter(res => res.status === 'fulfilled')?.map((res: any) => res.value);
						const allRooms: RoomInfoRead[] = [];
						const allWorkspaces: WorkspaceInfo[] = [];
						values.forEach(value => {
							allRooms.push(value.room);
							value.workspaces.forEach(workspace => allWorkspaces.push(workspace));
						});

						setAllRooms(allRooms);
						setAllWorkspaces(allWorkspaces);
					})
					.catch(error => console.log(error));
			})
			.catch(error => console.log(error));
	}, []);

	// TODO: make all booking cards props generated from all workspaces and all rooms
	const allRoomsBookingInfo: Array<BookingInfoCardData & { timeForSortStart: string; timeForSortEnd: string }> = [];
	const allWorkspacesBookingInfo: Array<BookingInfoCardData & { timeForSortStart: string; timeForSortEnd: string }> = [];

	// - filter
	allRooms?.filter(room =>
		bookingData?.body?.forEach(booking => {
			if (booking.parentId === room.id) {
				const { id, startTime, endTime, user } = booking;
				const address = officeList?.find(office => office.id === room.officeId)?.address;

				address &&
					allRoomsBookingInfo.push({
						bookingId: id,
						date: new Date(startTime),
						startDate: new Date(startTime),
						// endDate: endTime ? new Date(endTime) : null,
						startTime: DateTime.fromISO(startTime).toLocaleString({ hour: '2-digit', minute: '2-digit' }),
						endTime: endTime ? DateTime.fromISO(endTime).toLocaleString({ hour: '2-digit', minute: '2-digit' }) : null,
						userName: `${user.firstName} ${user.lastName}`,
						address,
						roomName: room.name,
						timeForSortStart: startTime,
						timeForSortEnd: endTime ?? '',
						userId,
					});
			}
		}),
	);

	allWorkspaces?.filter(workspace =>
		bookingData?.body?.forEach(booking => {
			if (booking.parentId === workspace.id) {
				const { id, startTime, endTime, user } = booking;
				const room = allRooms.find(room => room.id === workspace.roomId);
				const address = officeList?.find(office => office.id === room?.officeId)?.address;

				// если startTime === '00:00', то ставим время помещения
				const startTimeBooking = DateTime.fromISO(startTime).toLocaleString({ hour: '2-digit', minute: '2-digit' });
				const startTimeFormatDate = injectTimeToDate(new Date(startTime), `${room?.roomType.startTime}`);
				const startTimeWorkspace = DateTime.fromJSDate(startTimeFormatDate).toLocaleString({
					hour: '2-digit',
					minute: '2-digit',
				});

				// если endTime === '00:00', то ставим время помещения
				const endTimeBooking = endTime ? DateTime.fromISO(endTime).toLocaleString({ hour: '2-digit', minute: '2-digit' }) : null;
				const endTimeFormatDate = endTime ? injectTimeToDate(new Date(endTime), `${room?.roomType.endTime}`) : null;
				const endTimeWorkspace = endTimeFormatDate
					? DateTime.fromJSDate(endTimeFormatDate).toLocaleString({
							hour: '2-digit',
							minute: '2-digit',
						})
					: null;

				room &&
					address &&
					allWorkspacesBookingInfo.push({
						bookingId: id,
						date: new Date(startTime),
						startDate: new Date(startTime),
						endDate: endTime ? new Date(endTime) : null,
						startTime: startTimeBooking === '00:00' ? startTimeWorkspace : startTimeBooking,
						endTime: endTime ? (endTimeBooking === '00:00' ? endTimeWorkspace : endTimeBooking) : null,
						userName: `${user.firstName} ${user.lastName}`,
						address,
						roomName: room?.name,
						workspaceName: workspace.name,
						timeForSortStart: startTime,
						timeForSortEnd: endTime ?? '',
						userId,
					});
			}
		}),
	);

	const allBookingInfo = [...allRoomsBookingInfo, ...allWorkspacesBookingInfo];

	const relevantBookingInfo = allBookingInfo.filter(booking => booking.timeForSortEnd === '' || new Date(booking.timeForSortEnd).getTime() > new Date().getTime()); // Убираем брони из прошлого.

	const orderedAllBookingInfo = [...relevantBookingInfo].sort((a, b) => {
		return Date.parse(a.timeForSortStart) - Date.parse(b.timeForSortEnd);
	});

	return { isLoading, booking: orderedAllBookingInfo };
};
