import { FC, useState } from 'react';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import s from '../../styles/Content.module.scss';
import { TabCategories } from '../TabCategories';
import { TabGroups } from '../TabGroups';
import { TabTeams } from '../TabTeams';
import { TabUsers } from '../TabUsers';

interface Props {
	isCustom: boolean;
}

export const Content: FC<Props> = props => {
	const { isCustom } = props;

	// * TABS
	const TABS = [
		{
			id: 'users',
			name: 'Список сотрудников',
			visible: isCustom,
		},
		{
			id: 'categories',
			name: 'Категории',
			visible: true,
		},
		{
			id: 'groups',
			name: 'Группы',
			visible: true,
		},
		{
			id: 'teams',
			name: 'Команды',
			visible: true,
		},
	].filter(item => item.visible);

	const [activeTabId, setActiveTabId] = useState<string>(TABS[0].id);

	// * Render
	return (
		<div>
			<LineTabs
				className={s.tabs}
				tabs={TABS}
				activeTabId={activeTabId}
				setActiveTabId={setActiveTabId}
			/>

			{activeTabId === 'categories' && <TabCategories />}
			{activeTabId === 'groups' && <TabGroups />}
			{activeTabId === 'teams' && <TabTeams />}
			{activeTabId === 'users' && <TabUsers />}
		</div>
	);
};
