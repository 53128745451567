import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { useGetBookingFindQuery, useGetOfficeGetQuery, useGetRoomGetQuery } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { BookingInfo } from 'src/app/redux/state/office/booking/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { routerPath } from 'src/app/router/paths';
import { OfficeSchema } from 'src/entities/OfficeSchema/OfficeSchema';
import { getTimeInterval } from 'src/pages/booking/lib';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { isDateTheSame } from 'src/shared/lib/date';
import { createFullName } from 'src/shared/lib/string';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { DatePickerHeader } from 'src/shared/ui/_inputs/date_pickers/DatePickerHeader';
import { WeekPickerHeader } from 'src/shared/ui/_inputs/date_pickers/WeekPickerHeader';
import { BookingItem } from '../../../types';
import { FullBookingTable } from '../FullBookingTable';
import { PartialBookingTable } from '../PartialBookingTable';
import { RoomInfoCard } from '../RoomInfoCard/RoomInfoCard';
import { WorkspaceInfoCard } from '../WorkspaceInfoCard/WorkspaceInfoCard';
import s from './BookingRoomPage.module.scss';

export const BookingRoomPage: React.FC = () => {
	// * Router
	const navigate = useNavigate();
	const params = useParams<{ officeId: string; roomId: string }>();
	const officeId: string = params.officeId as string;
	const roomId: string = params.roomId as string;

	const setSelectedRoomId = (roomId: string) => {
		navigate(`${routerPath.booking.page}/${officeId}/${roomId}`);
	};

	const goToEditRoom = () => {
		navigate(`${routerPath.offices.page + routerPath.offices.roomForm}/${officeId}/${roomId}/${1}`);
	};

	// * Selectors
	const userInfo = useAppSelector(state => state.user_service.user.userInfo);

	// * Queries
	const { currentData: roomData } = useGetRoomGetQuery({ roomId });
	const { currentData: officeData } = useGetOfficeGetQuery({ officeId }, { skip: officeId === null });
	const { currentData: bookingData } = useGetBookingFindQuery({
		takecount: 1000,
		skipcount: 0,
		upcomingOnly: false,
	});

	// * Actions
	const dispatch = useAppDispatch();
	const { getImages } = officeServiceActions.image.async;
	const { clearImages } = officeServiceActions.image.actions;

	const fetchImages = () => {
		dispatch(
			getImages({
				params: {
					entityId: officeId,
				},
				options: {
					entity: 'schema',
				},
			}),
		);
	};

	// * Date selection
	const [selectedDate, setSelectedDate] = useState(new Date());

	// * SelectedIntervals
	const getSelectedIntervals = (bookingInfo: BookingInfo[]): BookingItem[] => {
		return bookingInfo
			.map(booking => {
				const selectedDateStart = DateTime.fromJSDate(selectedDate).startOf('day').toJSDate();

				const isCurrentDateSelected = isDateTheSame(new Date(booking.startTime), selectedDate);
				const selectedDateIsAfterStart = selectedDateStart.getTime() > new Date(booking.startTime).getTime();

				if (isCurrentDateSelected || (selectedDateIsAfterStart && booking.endTime === null)) {
					const bookingItem: BookingItem = {
						userName: createFullName({ lastName: booking.user.lastName, firstName: booking.user.firstName, middleName: booking.user.middleName }),
						userId: booking.user.id,
						rowId: booking.parentId,
						bookingId: booking.id,
						isBan: booking.isBan,
						comment: booking.comment,
						selectedInterval: getTimeInterval(booking.startTime, booking.endTime),
						startDate: new Date(booking.startTime),
						selectedDateIsAfterStart,
					};

					return bookingItem;
				} else {
					return undefined;
				}
			})
			.filter(booking => booking !== undefined) as BookingItem[];
	};

	// * Selected workspace
	const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | null>(null);
	const selectedWorkspace = roomData?.workspaces.find(workspace => workspace.id === selectedWorkspaceId);

	useEffect(() => {
		roomData &&
			setSelectedWorkspaceId(
				[...roomData.workspaces].sort((a, b) => {
					return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
				})[0]?.id,
			);
	}, [roomData]);

	// * Condition
	const roomType = roomData?.room.roomType.bookingRule;
	const initialized = userInfo && roomData && officeData && bookingData;
	const isAdmin = useRights();
	const hasRoomImages = roomData && roomData.room.images.length > 0;
	const hasOfficeImages = officeData && officeData.office.images && officeData.office.images.length > 0;

	// * Initialize
	useEffect(() => {
		if (hasOfficeImages) {
			fetchImages();
		}
		return () => {
			dispatch(clearImages('schema'));
		};
	}, []);

	// * Render
	return (
		<>
			<PageDecorator>
				<h1 className={s.title}>Бронирование мест</h1>

				<div className={s.inner}>
					<div className={s.table}>
						<div className={s.table_header}>
							<div className={s.names}>
								<h3>{roomData?.room.name}</h3>
								<h5>{officeData?.office.name}</h5>
							</div>

							{roomType === 'FullBooking' && (
								<DatePickerHeader
									selectedDate={selectedDate}
									setSelectedDate={date => setSelectedDate(date)}
								/>
							)}

							{roomType === 'PartialBooking' && (
								<WeekPickerHeader
									selectedDate={selectedDate}
									setSelectedDate={date => setSelectedDate(date)}
								/>
							)}

							<div className={s.table_buttons}>
								{officeId && (
									<OfficeSchema
										officeId={officeId}
										buttonVariant="secondary"
										imagesOnly
										hasImages={hasOfficeImages}
									/>
								)}

								{isAdmin && (
									<DropDownMenu
										items={[
											{
												title: 'Редактировать',
												icon: <EditSVG />,
												action: () => goToEditRoom(),
											},
										]}
									/>
								)}
							</div>
						</div>

						{initialized && roomType === 'FullBooking' && (
							<FullBookingTable
								userInfo={userInfo}
								setSelectedRoomId={setSelectedRoomId}
								bookingInterval={getTimeInterval(officeData.office.startTime, officeData.office.endTime)} // TODO: Probably better calculate inside.
								officeData={officeData}
								roomData={roomData}
								selectedIntervals={getSelectedIntervals(bookingData.body)} // TODO: Probably better calculate inside.
								selectedDate={selectedDate}
							/>
						)}

						{initialized && roomType === 'PartialBooking' && (
							<PartialBookingTable
								userInfo={userInfo}
								officeData={officeData}
								roomData={roomData}
								selectedDate={selectedDate}
								allBookingInfo={bookingData.body}
								selectedWorkSpaceId={selectedWorkspaceId}
								setSelectedWorkSpaceId={setSelectedWorkspaceId}
							/>
						)}
					</div>

					{selectedWorkspace && selectedWorkspace.tags.length > 0 && (
						<WorkspaceInfoCard
							className={s.workSpaceInfo}
							workspaceName={selectedWorkspace.name}
							tags={selectedWorkspace.tags}
						/>
					)}

					{roomData && (
						<RoomInfoCard
							room={roomData.room}
							hasImages={hasRoomImages}
						/>
					)}
				</div>
			</PageDecorator>
		</>
	);
};
