import * as Sentry from '@sentry/browser';

const ERRORS: { [key: number]: string } = {
	400: '400 Bad request',
	403: '403 Forbidden',
	404: '404 Not found',
	429: '429 Too many requests',
	500: '500 Internal server error',
	502: '502 Bad gateway',
};

export const captureException = (status: number, res: any) => {
	const mes = JSON.stringify(res);
	const typeOfError = ERRORS[status] || 'Something went wrong';

	Sentry.captureException(new Error(typeOfError), {
		tags: {
			status: status,
		},
		extra: {
			message: mes,
		},
	});
};
