import { ReactComponent as SearchSVG } from 'src/shared/assets/svg/action/search.svg';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';
import s from './Filters.module.scss';

export const Filters = () => {
	// * Render
	return (
		<div className={s.container}>
			<TextInput // TODO
				value={''}
				placeholder="Поиск по ФИО"
				onChange={event => console.log(event)}
				RightIcon={<SearchSVG />}
			/>

			<SelectSingle // TODO
				placeholder="Статус"
				keyNames={{
					name: 'name',
					value: 'value',
				}}
				options={[{ name: 'Статус: все', value: 'all' }]}
				selectedOption={{ name: 'Статус: все', value: 'all' }}
				setSelectedOption={value => console.log(value)}
			/>
		</div>
	);
};
