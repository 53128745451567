import { FC, useState } from 'react';
import { Heading } from 'src/shared/ui/Heading';
import { SubGroupType } from '../../types';
import s from './SubGroupUsers.module.scss';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { SelectStringSearchFilter, SelectValue } from 'src/entities/_filters/SelectStringSearchFilter';
import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { SelectedCount } from 'src/entities/_typography/SelectedCount';

interface Props {
	type: SubGroupType;
	name: string;
}

const SUB_GROUP = {
	category: 'Категория',
	group: 'Группа',
	team: 'Команда',
};

export const SubGroupUsers: FC<Props> = props => {
	const { type, name } = props;

	const [isOpen, setIsOpen] = useState(false);
	const toggleModal = () => setIsOpen(prevState => !prevState);

	// * API
	const { currentData: positionsData } = position_serviceAPI.useFindPositionsQuery({
		skipcount: 0,
		takecount: 1000,
		includeDeactivated: false,
	});
	const allPositions = positionsData?.body || [];

	// * Filters
	const [position, setPosition] = useState<SelectValue>({ id: '', name: '' });
	const [searchValue, setSearchValue] = useState('');

	// * Render
	return (
		<>
			<div className={s.container}>
				<Heading level={2}>
					{SUB_GROUP[type]} <span className={s.subgroup_name}>{`“${name}”`}</span>
				</Heading>

				<SelectStringSearchFilter
					selectPlaceholder="Должность"
					selectValue={position}
					setSelect={(value: SelectValue) => setPosition(value)}
					selectOptions={allPositions}
					searchPlaceholder="Введите ФИО сотрудника"
					searchStringValue={searchValue}
					setSearchString={setSearchValue}
				/>

				<div className={s.inner}>
					<SelectedCount
						title="Выбрано сотрудников"
						count={0}
					/>

					<AddButton
						title="Добавить сотрудников "
						onClick={toggleModal}
					/>
				</div>
			</div>
		</>
	);
};
