import React, { ComponentPropsWithoutRef } from 'react';
import s from './ModalHeader.module.scss';
import cn from 'classnames';

interface Props extends ComponentPropsWithoutRef<'div'> {
	title?: string;
	placeName?: string;
	extraParagraph?: string;
	noMargins?: boolean;
}

export const ModalHeader: React.FC<Props> = props => {
	const {
		title, //
		placeName,
		extraParagraph,
		noMargins,
		className,
		...divProps
	} = props;

	// * Render
	return (
		<div
			className={cn(s.container, noMargins && s.noMargins, className)}
			{...divProps}
		>
			{title && <h3>{title}</h3>}
			{placeName && <h3 className={s.place_name}>{placeName}</h3>}
			{extraParagraph && <p>{extraParagraph}</p>}
		</div>
	);
};
