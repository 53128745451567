import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RequestStatus } from 'src/app/api/api_types';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';
import { captureException } from 'src/shared/lib/app';
import { getErrorMessage } from '../errors/getErrorMessage';
import { actionsAuth, asyncActionsAuth } from './state/auth/slice';

export const handleResponse = async <Data>(
	response: Response,
	action: any,
	thunkAPI: { rejectWithValue: any; dispatch: ThunkDispatch<any, any, AnyAction>; signal?: AbortSignal; getState: any },
	showNotification = true,
): Promise<Data> => {
	const { rejectWithValue, dispatch, getState } = thunkAPI;

	if (response.status === 401) {
		const refreshToken = localStorage.getItem('refresh_token');
		const refreshStatus = getState().auth_service.status;

		if (refreshStatus === RequestStatus.loading) {
			const interval = setInterval(() => {
				const refreshStatus = getState().auth_service.status;

				if (refreshStatus === RequestStatus.still) {
					dispatch(action());
					clearInterval(interval);
				} else if (refreshStatus === RequestStatus.failed) {
					dispatch(actionsAuth.logout());
					clearInterval(interval);
				}
			}, 1000);
		} else {
			refreshToken &&
				dispatch(asyncActionsAuth.refresh({ refreshToken }))
					.unwrap()
					.then(() => {
						dispatch(action());
					})
					.catch(() => dispatch(actionsAuth.logout()));
		}
	}

	const parsedResponse = await response.json();

	if (response.status >= 400 && response.status !== 401) {
		captureException(response.status, parsedResponse);
	}

	if (response.status < 200 || response.status >= 300) {
		if (response.status !== 404 && showNotification) {
			// There is no reason to show error on 404.
			dispatch(
				actionsNotifications.addNotification({
					type: 'error',
					message: parsedResponse.Message ? getErrorMessage(parsedResponse.Message) : 'Произошла ошибка',
				}),
			);
		}
		throw rejectWithValue(parsedResponse);
	}

	return parsedResponse as Data;
};

export const handlePublicResponse = async <Data>(
	response: Response,
	thunkAPI: { rejectWithValue: any; dispatch: ThunkDispatch<any, any, AnyAction>; signal?: AbortSignal },
	showNotification = true,
): Promise<Data> => {
	const { rejectWithValue, dispatch } = thunkAPI;

	const parsedResponse = await response.json();

	if (response.status < 200 || response.status >= 300) {
		if (response.status !== 404 && showNotification) {
			// There is no reason to show error on 404.
			dispatch(
				actionsNotifications.addNotification({
					type: 'error',
					message: parsedResponse.Message ? parsedResponse.Message : 'Произошла ошибка',
				}),
			);
		}
		throw rejectWithValue(parsedResponse);
	}

	return parsedResponse as Data;
};
