import { useState } from 'react';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { AddSubGroupMC } from '../../../AddSubGroupMC';
import { SubGroupCard } from '../../../SubGroupCard';
import s from '../../styles/Content.module.scss';

const HARDCODE = [
	{
		id: '1',
		name: 'DevOps',
		count: 0,
	},
	{
		id: '2',
		name: 'QA-тестировщики',
		count: 1,
	},
];

export const TabCategories = () => {
	// * Modals
	const [isOpenForm, setIsOpenForm] = useState(false);
	const toggleFormModal = () => setIsOpenForm(prevState => !prevState);

	// * Actions
	const addCategory = (data: { name: string }) => {
		console.log('add category: ', data.name);
	};

	const editCategory = (id: string, name: string) => {
		console.log('edit category ', name, ' by id ', id);
	};

	const deleteCategory = (id: string) => {
		console.log('delete category by id ', id);
	};

	// * Render
	return (
		<>
			{isOpenForm && (
				<ModalNewProto
					isOpen={isOpenForm}
					onClose={toggleFormModal}
					width="s"
				>
					<AddSubGroupMC
						type="category"
						closeModal={toggleFormModal}
						onSubmitAction={addCategory}
					/>
				</ModalNewProto>
			)}

			<div className={s.tab_container}>
				<AddButton
					title="Добавить категорию"
					onClick={toggleFormModal}
				/>

				{HARDCODE.map(category => (
					<SubGroupCard
						key={category.id}
						{...category}
						type="category"
						editAction={editCategory}
						deleteAction={deleteCategory}
					/>
				))}
			</div>
		</>
	);
};
