import React from 'react';
import s from './OfficeCardDetailed.module.scss';
import { getTimeRangeString } from 'src/shared/lib/date';
import { ReactComponent as CitySVG } from 'src/shared/assets/svg/social/city.svg';
import { ReactComponent as ClockSVG } from 'src/shared/assets/svg/action/clock.svg';
import { ReactComponent as PersonSVG } from 'src/shared/assets/svg/social/person.svg';
import { ReactComponent as DoorSVG } from 'src/shared/assets/svg/social/door.svg';
import { ReactComponent as AddressSVG } from 'src/shared/assets/svg/social/address.svg';
import { ReactComponent as FlagSVG } from 'src/shared/assets/svg/action/flag.svg';
import { Chip } from 'src/shared/ui/_chips/Chip/Chip';
import { OfficeInfoItem } from '../../../OfficeInfoItem/OfficeInfoItem';
import { Office } from 'src/app/redux/state/office/types';
import { OfficeInnerMenu } from '../../../OfficeInnerMenu';
import { useNavigate } from 'react-router-dom';
import { routerPath } from 'src/app/router/paths';
import { OfficeSchema } from 'src/entities/OfficeSchema/OfficeSchema';

interface Props {
	office: Office;
	companyName: string;
	roomsAmount: number;
	peopleAmount: number;
}

export const OfficeCardDetailed: React.FC<Props> = props => {
	const { office, companyName, roomsAmount, peopleAmount } = props;

	const { id, name, city, address, description, startTime, endTime, isActive, images } = office;

	const officeTime = getTimeRangeString(startTime, endTime);

	// * Router
	const navigate = useNavigate();

	// * Interactions
	const onArchiveExtra = () => {
		navigate(routerPath.offices.page);
	};

	const officeInfo = [
		{
			icon: <CitySVG />,
			text: 'Город',
			info: city,
		},
		{
			icon: <FlagSVG />,
			text: 'Компания',
			info: companyName,
		},
		{
			icon: <AddressSVG />,
			text: 'Адрес',
			info: address,
		},
		{
			icon: <DoorSVG />,
			text: 'Помещений',
			info: `${roomsAmount}`,
		},
		{
			icon: <ClockSVG />,
			text: 'Время работы',
			info: officeTime,
		},
		{
			icon: <PersonSVG />,
			text: 'Сотрудников',
			info: `${peopleAmount}`,
		},
	];

	// * Render
	return (
		<>
			<div className={s.container}>
				<div className={s.card}>
					<div className={s.header_container}>
						<div className={s.header_container_small}>
							{isActive ? (
								<Chip
									variant="green"
									text="Открыт"
								/>
							) : (
								<Chip
									variant="red"
									text="Закрыт"
								/>
							)}
							<h3 className={s.name}>{name}</h3>
						</div>

						<div className={s.header_container_small}>
							<OfficeSchema
								officeId={id}
								hasImages={images && images.length > 0}
							/>

							<OfficeInnerMenu
								office={office}
								onArchiveExtra={onArchiveExtra}
							/>
						</div>
					</div>

					<div className={s.description}>{description}</div>

					<div className={s.info_container}>
						{officeInfo.map(item => (
							<OfficeInfoItem
								key={item.text}
								Icon={item.icon}
								text={item.text}
								info={item.info}
							/>
						))}
					</div>
				</div>
			</div>
		</>
	);
};
