import { FC } from 'react';
import { routerPath } from 'src/app/router/paths';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';

interface Props {
	isCustom: boolean;
}

export const Header: FC<Props> = props => {
	const { isCustom } = props;

	// *Render
	return (
		<PageTitle
			title="TODO"
			goBackRoute={{
				text: 'Планировщик отпусков',
				route: routerPath.vacation.page,
			}}
			buttons={[
				isCustom && (
					<DropDownMenu
						key={0}
						items={[
							{
								icon: <EditSVG />,
								title: 'Редактировать',
								action: () => console.log('Настроить группу'),
							},
							{
								icon: <DeleteSVG />,
								title: 'Удалить',
								action: () => console.log('Удалить группу'),
							},
						]}
					/>
				),
			]}
		/>
	);
};
