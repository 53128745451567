import { useRef, useState } from 'react';
import { CELL_GAP, COLUMNS_META, DIVIDER_WIDTH } from '../../consts';
import { MOCK_CALENDAR, MOCK_USERS } from '../../mock';
import { SortBy } from '../../types';
import { MonthSelector } from '../MonthSelector';
import { ScrollbarWrapper } from '../ScrollbarWrapper';
import { Table } from '../Table/ui/Table/Table';
import { UsersTable } from '../UsersTable';
import s from './VacationTable.module.scss';

export const VacationTable: React.FC = () => {
	const users = MOCK_USERS;

	const [sortBy, setSortBy] = useState<{ ascending: boolean; by: SortBy } | null>(null);

	const sortedUsers = sortBy
		? users.sort((a, b) => {
				if (sortBy.ascending) {
					return a[sortBy.by] > b[sortBy.by] ? 1 : -1;
				} else {
					return a[sortBy.by] < b[sortBy.by] ? 1 : -1;
				}
			})
		: users;

	const leftSideWidth = COLUMNS_META.reduce((sum, current) => (sum += current.width + CELL_GAP), 0) + DIVIDER_WIDTH; // TODO: Заменить вычисления на реальное количество колонок.

	const tableRef = useRef<HTMLDivElement>(null);

	const [selectedYear, setSelectedYear] = useState(MOCK_CALENDAR[0]);

	// * Render
	return (
		<div className={s.container}>
			<ScrollbarWrapper
				usersTableWidth={leftSideWidth}
				ref={tableRef}
			>
				<div className={s.table}>
					<UsersTable
						users={sortedUsers}
						sortBy={sortBy}
						setSortBy={setSortBy}
					/>

					<Table
						users={sortedUsers}
						selectedYear={selectedYear}
					/>
				</div>
			</ScrollbarWrapper>

			<MonthSelector
				width={`calc(100% - ${leftSideWidth}rem)`}
				selectedYear={selectedYear}
				tableRef={tableRef}
			/>
		</div>
	);
};
