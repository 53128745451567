import React, { ReactElement, useState } from 'react';
import s from './DropDownMenu.module.scss';
import { ReactComponent as DotsSVG } from 'src/shared/assets/svg_icons/action/kebab_menu_vertical.svg';
import { IconButton } from '../_buttons/IconButton';
import { useFloatingWrapper } from 'src/shared/hooks/useFloatingWrapper';
import { FloatingFocusManager } from '@floating-ui/react';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';

export interface DropDownItem {
	title: string;
	action: (value?: string) => void;
	icon?: ReactElement;
}

interface Props {
	items: DropDownItem[];
	value?: string;
	CustomIcon?: ReactElement;
	isLoading?: boolean;
}

export const DropDownMenu: React.FC<Props> = props => {
	const {
		items, //
		value,
		CustomIcon,
		isLoading,
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		setIsOpen(!isOpen);
	};

	const onMenuItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, action: (value?: string) => void) => {
		event.stopPropagation();
		action(value);
		setIsOpen(false);
	};

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(isOpen, setIsOpen);

	// * Render
	return (
		<div
			ref={refs.setReference}
			{...getReferenceProps()}
		>
			{isLoading ? (
				<LoaderCircle />
			) : (
				<IconButton
					Icon={CustomIcon ? CustomIcon : <DotsSVG />}
					onClick={toggleMenu}
				/>
			)}

			{isOpen && (
				<FloatingFocusManager
					context={context}
					modal={false}
				>
					<div
						ref={refs.setFloating}
						style={{
							...floatingStyles,
							zIndex: 'var(--z-index-floating)',
						}}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						<div className={s.menu}>
							{items.map(item => (
								<div
									key={item.title}
									className={s.item_container}
									onClick={event => onMenuItemClick(event, item.action)}
								>
									{item.icon}
									<span>{item.title}</span>
								</div>
							))}
						</div>
					</div>
				</FloatingFocusManager>
			)}
		</div>
	);
};
