import { useState } from 'react';
import { Button } from 'src/shared/ui/_buttons/Button';
import { DatePickerTitleButton } from 'src/shared/ui/_inputs/date_pickers/DatePickerTitleButton';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { SendMailModal } from '../_modals/SendMailModal';
import s from './SubHeader.module.scss';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { PlannedVacationMC } from '../_modals/PlannedVacationMC';

export const SubHeader = () => {
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());

	// * Modals
	const [modalSendEmail, setModalSendEmail] = useState<boolean>(false);
	const toggleModalSendEmail = () => setModalSendEmail(prevState => !prevState);

	const [modalVacation, setModalVacation] = useState<boolean>(false);
	const toggleModalVacation = () => setModalVacation(prevState => !prevState);

	// * Render
	return (
		<>
			{modalSendEmail && (
				<SendMailModal
					modalSendEmail={modalSendEmail}
					toggleModalSendEmail={toggleModalSendEmail}
				/>
			)}

			{modalVacation && (
				<ModalNewProto
					isOpen={modalVacation}
					onClose={toggleModalVacation}
					width='s'
				>
					<PlannedVacationMC toggleModal={toggleModalVacation} />
				</ModalNewProto>
			)}

			<div className={s.container}>
				<DatePickerTitleButton // TODO
					prefix="TODO"
					selectedDate={selectedDate}
					setSelectedDate={setSelectedDate}
					format="year"
					size="large"
				/>

				<div>
					<Button onClick={toggleModalVacation}>Внести планируемый отпуск</Button>

					<DropDownMenu // TODO
						items={[
							{
								title: 'Настроить группу',
								action: () => console.log('Настроить группу'),
							},
							{
								title: 'Отправить e-mail',
								action: toggleModalSendEmail,
							},
							{
								title: 'Выгрузить в Excel',
								action: () => console.log('Выгрузить в Excel'),
							},
							{
								title: 'Утвердить план',
								action: () => console.log('Утвердить план'),
							},
						]}
					/>
				</div>
			</div>
		</>
	);
};
