import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import s from './Groups.module.scss';

export const Groups = () => {
	return (
		<LineTabs
			className={s.container}
			tabs={[
				{
					id: 'ДМС',
					name: 'ДМС',
				},
				{
					id: 'ЕМИАС',
					name: 'ЕМИАС',
				},
			]}
			activeTabId="ДМС"
			setActiveTabId={activeTabId => console.log(activeTabId)}
		/>
	);
};
