export const COLUMNS_META = [
	{ id: 'category' as const, name: 'Категория', width: 6.375 },
	{ id: 'name' as const, name: 'ФИО', width: 16.25 },
	{ id: 'group' as const, name: 'Группа', width: 4.9375 },
	{ id: 'team' as const, name: 'Команда', width: 9.875 },
	{ id: 'days' as const, name: 'Дни', width: 4.75 },
];

export const VACATION_MAP = {
	sickLeave: {
		name: 'Больничный',
		color: '#F4D775',
	},
	planedVacation: {
		name: 'Планируемый отпуск',
		color: '#C4D593',
	},
	vacation: {
		name: 'Фактический отпуск',
		color: '#73c171',
	},
	idle: {
		name: 'Отгул',
		color: '#D6A4DE',
	},
	training: {
		name: 'Обучение',
		color: '#97CED2',
	},
};

export const CELL_SIZE = 1.5; // rem
export const CELL_GAP = 0.125; // rem
export const MONTH_GAP = 0.75; // rem
export const DIVIDER_WIDTH = 0.25; // rem
