import { FC } from 'react';
import { Heading } from 'src/shared/ui/Heading';
import s from './PlannedVacationMC.module.scss';
import { ValidationRequirements } from 'src/shared/ui/ValidationRequirements';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Button } from 'src/shared/ui/_buttons/Button';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';

interface Props {
	toggleModal: () => void;
}

export const PlannedVacationMC: FC<Props> = props => {
	const { toggleModal } = props;

	const formMethods = useForm({
		defaultValues: {
			vacation: [
				{
					name: 'test',
				},
				{
					name: 'test 2',
				},
			],
		},
		// resolver: yupResolver(schema),
	});

	const { control } = formMethods;

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control,
		name: 'vacation',
	});

	const { handleSubmit, watch, setError, formState, clearErrors } = formMethods;

	const onSubmit = (values: any) => {
		console.log();
	};

	const onFieldAdd = () => {
		append({ name: 'test' });
		// remove(0);
	};

	console.log('fields :>> ', fields);

	// * Render
	return (
		<div className={s.container}>
			<Heading
				className={s.heading}
				level={2}
				marginBottom="l"
			>
				Внести
				<br /> планируемый отпуск
			</Heading>

			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ValidationRequirements
						className={s.requirements}
						requirements={[
							{
								text: 'Количество внесённых дней отпуска не может превышать количество доступных дней отпуска',
								isDone: true,
							},
							{
								text: 'Хотя бы один из интервалов отпуска должен быть не менее 14 дней',
								isDone: false,
							},
						]}
					/>

					<div className={s.available}>
						<div>
							<div className={s.available__label}>Доступно дней отпуска</div>
							<div className={s.available__value}>28 TODO</div>
						</div>

						<div>
							<div className={s.available__label}>Внесено дней отпуска</div>
							<div className={s.available__value}>28 TODO</div>
						</div>
					</div>

					<div className={s.fields}>
						{fields.map((field, index) => (
							<StringField
								key={field.id}
								name={`vacation.${index}.name`}
							/>
						))}
					</div>

					<Button
						variant="secondary"
						onClick={onFieldAdd}
						wide
					>
						Добавить интервал отпуска
					</Button>
				</form>
			</FormProvider>
		</div>
	);
};
