import { FC } from 'react';
import { PageDecorator } from 'src/app/decorators/page_decorators';
import { VacationTable } from '../VacationTable';
import { Filters } from '../Filters';
import { Groups } from '../Groups';
import { Header } from '../Header';
import { SubHeader } from '../SubHeader';

export const VacationPage: FC = () => {
	// * Render
	return (
		<PageDecorator>
			<Header />
			<Groups />
			<SubHeader />
			<Filters />

			<VacationTable />
		</PageDecorator>
	);
};
