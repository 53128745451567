import { FC } from 'react';
import { MCType } from '../../types';
import { Heading } from 'src/shared/ui/Heading';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';

interface Props {
	setActiveMC: React.Dispatch<React.SetStateAction<MCType>>;
}

export const AddAddresseeMC: FC<Props> = props => {
	const {
		setActiveMC, //
	} = props;

	const onSaveClick = () => {
		setActiveMC('email');
	};

	const onCancelClick = () => {
		setActiveMC('email');
	};

	// * Render
	return (
		<div>
			<Heading
				level={2}
				marginBottom="l"
			>
				Добавить адресата
			</Heading>

			{`<AddUsersMC/>`}

			<ButtonPair
				primaryText="Добавить"
				primaryOnClick={onSaveClick}
				primaryIsLoading={false} // TODO
				primaryDisabled={false} // TODO
				secondaryText="Отменить"
				secondaryOnClick={onCancelClick}
				secondaryIsLoading={false} // TODO
			/>
		</div>
	);
};
