import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { CheckboxField } from 'src/shared/ui/_fields/CheckboxField';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { Heading } from 'src/shared/ui/Heading';
import * as yup from 'yup';
import s from './NewGroupMC.module.scss';

const DEFAULT_VALUES = {
	name: '',
	shortName: '',
	isEmailSend: false,
};

interface Props {
	closeModal: () => void;
}

export const NewGroupMC: React.FC<Props> = props => {
	const { closeModal } = props;

	const schema = yup.object().shape({
		name: yup.string().required('Введите название группы'),
		shortName: yup.string().required('Введите короткое группы'),
	});

	const formMethods = useForm({
		defaultValues: DEFAULT_VALUES,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch, setError, formState, clearErrors } = formMethods;

	const onSubmit = (data: typeof DEFAULT_VALUES) => {
		console.log('data :>> ', data); // TODO
	};

	// * Render
	return (
		<div className={s.container}>
			<Heading level={2}>Новая группа</Heading>

			<FormProvider {...formMethods}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className={s.inner}
				>
					<StringField
						name="name"
						label="Полное название группы"
						placeholder="Введите полное название"
					/>

					<StringField
						name="shortName"
						label="Сокращённое название"
						placeholder="Введите сокращённое название"
					/>

					<CheckboxField
						name="isEmailSend"
						label="Высылать e-mail о пересечениях отпусков"
					/>

					<ButtonPair
						primaryText="Создать"
						primaryIsLoading={false} // TODO
						primaryDisabled={false} // TODO
						secondaryText="Отмена"
						secondaryOnClick={closeModal}
						secondaryIsLoading={false} // TODO
					/>
				</form>
			</FormProvider>
		</div>
	);
};
