import { FC, useState } from 'react';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { SendEmailMC } from '../SendEmailMC';
import { AddAddresseeMC } from '../AddAddresseeMC';
import { MCType } from '../../types';

interface Props {
	modalSendEmail: boolean;
	toggleModalSendEmail: () => void;
}

export const SendMailModal: FC<Props> = props => {
	const {
		modalSendEmail, //
		toggleModalSendEmail,
	} = props;

	const [activeMC, setActiveMC] = useState<MCType>('email');

	const MC = {
		email: (
			<SendEmailMC
				toggleModal={toggleModalSendEmail}
				setActiveMC={setActiveMC}
			/>
		),
		addressee: <AddAddresseeMC setActiveMC={setActiveMC} />,
	};

	// * Render
	return (
		<ModalNewProto
			isOpen={modalSendEmail}
			onClose={toggleModalSendEmail}
			width="l"
		>
			{MC[activeMC]}
		</ModalNewProto>
	);
};
