import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import React, { useEffect } from 'react';
import { bookingPageActions } from 'src/app/redux/state/office/_states/booking_page_state';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { OfficeSchema } from 'src/entities/OfficeSchema/OfficeSchema';
import mapIMG from 'src/shared/assets/img/map.png';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle';
import s from './OfficeSelectionCard.module.scss';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { getPriorityOfficeId } from 'src/pages/booking/lib';

interface Props {
	setOfficeId: (officeId: string) => void;
}

export const OfficeSelectionCard: React.FC<Props> = props => {
	const { setOfficeId } = props;

	// * Selectors
	const selectedAddress = useAppSelector(state => state.bookingPage.selectedOffice);
	const officesList = useAppSelector(state => state.office_service.office.officesList);
	const officesListStatus = useAppSelector(state => state.office_service.office.status);

	const officesOptions = officesList
		? officesList.map(office => ({
				...office,
				name: `${office.name}, ${office.address}`,
			}))
		: [];

	// * Actions
	const dispatch = useAppDispatch();
	const { setSelectedAddress } = bookingPageActions;
	const { findOffices } = officeServiceActions.office.async;

	// * Initial request
	useEffect(() => {
		dispatch(
			findOffices({
				includeDeactivated: false,
				takeCount: 1000,
				skipCount: 0,
			}),
		);
	}, []);

	// * selectOffice
	useEffect(() => {
		if (selectedAddress.id) {
			setOfficeId(selectedAddress.id);
		} else {
			setOfficeId('');
		}
	}, [selectedAddress]);

	// * ----- Selectors
	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	// * API
	const { data: userInfo, isSuccess } = user_serviceAPI.useGetUserGetQuery(
		{
			userid: userId,
			includeoffice: true,
		},
		{ skip: !userId },
	);

	const offices = userInfo?.body?.offices || [];

	// * Set initial office id
	useEffect(() => {
		// * В слайсе при успешном запросе у officesListStatus статус STILL
		if (!isSuccess || officesListStatus !== 'STILL') return;

		if (selectedAddress.id) {
			setOfficeId(selectedAddress.id);
			return;
		}

		const initialOffice = getPriorityOfficeId(offices || [], officesList || []);

		initialOffice && dispatch(setSelectedAddress(initialOffice));
	}, [isSuccess, officesListStatus]);

	// * Render
	return (
		<div className={s.container}>
			<h3>Выбор офиса</h3>

			<SelectSingle
				label="Адрес"
				placeholder="Выберите офис из списка"
				keyNames={{
					name: 'name',
					value: 'id',
				}}
				options={officesOptions}
				selectedOption={selectedAddress}
				setSelectedOption={value => dispatch(setSelectedAddress(value))}
			/>

			{selectedAddress.id ? (
				<div className={s.wrapper}>
					{selectedAddress.longitude && selectedAddress.latitude ? (
						<div className={s.map}>
							<YMaps>
								<Map
									state={{
										center: [selectedAddress.latitude, selectedAddress.longitude],
										zoom: 10,
									}}
									height="18rem"
									width="100%"
								>
									<Placemark geometry={[selectedAddress.latitude, selectedAddress.longitude]} />
								</Map>
							</YMaps>
						</div>
					) : (
						<div className={s.map}>
							<img
								className={s.map__image}
								src={mapIMG}
								alt="Карта"
							/>
							<p className={s.map__placeholder}>{'Координаты отсутствуют'}</p>
						</div>
					)}

					<div className={s.officeAbout}>
						{selectedAddress.id && (
							<OfficeSchema
								officeId={selectedAddress.id}
								buttonVariant="secondary"
								buttonWide
								imagesOnly
								hasImages={selectedAddress.images && selectedAddress.images.length > 0}
							/>
						)}
					</div>
				</div>
			) : (
				<div className={s.map}>
					<img
						className={s.map__image}
						src={mapIMG}
						alt="Карта"
					/>
					<p className={s.map__placeholder}>{'Выберите офис \n для продолжения бронирования'}</p>
				</div>
			)}
		</div>
	);
};
