import { useState } from 'react';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { AddSubGroupMC } from '../../../AddSubGroupMC';
import { SubGroupCard } from '../../../SubGroupCard';
import s from '../../styles/Content.module.scss';

const HARDCODE = [
	{
		id: '1',
		name: 'Front-end',
		count: 2,
	},
	{
		id: '2',
		name: 'Back-end',
		count: 2,
	},
];

export const TabTeams = () => {
	// * Modals
	const [isOpenForm, setIsOpenForm] = useState(false);
	const toggleFormModal = () => setIsOpenForm(prevState => !prevState);

	// * Actions
	const addTeam = (data: { name: string }) => {
		console.log('add team: ', data.name);
	};

	const editTeam = (id: string, name: string) => {
		console.log('edit team ', name, ' by id ', id);
	};

	const deleteTeam = (id: string) => {
		console.log('delete team by id ', id);
	};

	// * Render
	return (
		<>
			{isOpenForm && (
				<ModalNewProto
					isOpen={isOpenForm}
					onClose={toggleFormModal}
					width="s"
				>
					<AddSubGroupMC
						type="team"
						closeModal={toggleFormModal}
						onSubmitAction={addTeam}
					/>
				</ModalNewProto>
			)}

			<div className={s.tab_container}>
				<AddButton
					title="Добавить команду"
					onClick={toggleFormModal}
				/>

				{HARDCODE.map(team => (
					<SubGroupCard
						key={team.id}
						{...team}
						type="team"
						editAction={editTeam}
						deleteAction={deleteTeam}
					/>
				))}
			</div>
		</>
	);
};
